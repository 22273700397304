export enum HelpPage {
    periumIntroduction = 'introductie_perium',
    dashboard = '12_-_dashboard',
    actionPlan = '11_-_actieplannen',
    assetcontrolassessments = '10_-_asset-beheersmaatregel_assessments',
    controlassessments = '9_-_beheersmaatregel_assessments',
    controls = '8_-_beheersmaatregelen',
    riskassessments = '7_-_risico_assessments',
    assets = '6_-_assets',
    risks = '5_-_risico',
    vulnerabilities = '4_-_kwetsbaarheden',
    threats = '3_-_bedreigingen',
    organisationSettings = '2_-_organisatie_instellingen',
    norms = '1_-_normen',
    helpIndex = '13_-_help_index',
    tasks = '14_-_task'
}