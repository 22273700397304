






























































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useActions, useState } from '@/shared/mixins/helpers'
import utils from '@/shared/mixins/utils'
import variables from '@/shared/variables'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import helpQueries from '@/shared/queries/helpQueries'
import { UserRole } from '@/shared/enum/general-enum'
import { useToast } from 'vue-toastification/composition'
import md2html from '@/shared/mixins/markdown2html'
import { HelpPage } from '@/shared/enum/help-enum'

export default defineComponent({
    name: 'Help',

    setup() {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const { SET_OPEN_MENU } = useActions(['SET_OPEN_MENU'])
        const { SET_LEFT_MENU_SIDEBAR } = useActions(['SET_LEFT_MENU_SIDEBAR'])
        const { menuOpen } = useState(['menuOpen'])
        const visibleLeft = ref(false)
        const editable = ref(false)
        const HELP_TEXT = ref('')
        const { role } = useState(['role'])
        const toast = useToast()
        const helpContent = ref({
            body: '',
            title: '',
        })

        // get table of content of help page
        const getHelpList = async () => {
            const helpQuery = `
                query{
                    helpPages{
                        helpPages{
                            ${helpQueries.HELP_LIST}
                        }
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(helpQuery)
            } catch {
                throw Error('Error while retrieving help page content')
            }

            if (result && result.data.helpPages) {
                const helpList = result.data.helpPages.helpPages
                helpContent.value = helpList.find((help) => help.key === HelpPage.helpIndex)
                if (helpContent.value) {
                    HELP_TEXT.value = helpContent.value.body
                }
            }
        }

        // save help content (index page)
        const save = async (params: { key: string; body: string }) => {
            const key = params.key
            const body = params.body
            const helpQuery = `
                    mutation updateHelpPage($key:String, $input: HelpPageInput){
                        updateHelpPage(key: $key, input: $input){
                            status
                            error
                        }
                    }
                
            `
            const input = {
                body,
            }
            const helpVariables = {
                key,
                input,
            }
            let result
            try {
                result = await apolloClient.getGraphqlData(helpQuery, helpVariables)
            } catch (err) {
                toast.error(t('HELP_CONTENT_SAVED_ERROR_MESSAGE', language.value))
                throw Error('Error while saving help content')
            }
            if (result.data && result.data.updateHelpPage.status) {
                toast.success(t('HELP_CONTENT_SAVED_SUCCESS_MESSAGE', language.value))
                editable.value = false
                getHelpList()
            }
        }

        // cancel
        const cancel = () => {
            editable.value = false
            HELP_TEXT.value = helpContent.value.body
        }

        onMounted(() => {
            getHelpList()
        })

        return {
            t,
            ...useI18n(),
            language,
            utils,
            SET_OPEN_MENU,
            SET_LEFT_MENU_SIDEBAR,
            menuOpen,
            visibleLeft,
            variables,
            editable,
            HELP_TEXT,
            UserRole,
            role,
            save,
            cancel,
            helpContent,
            md2html,
            HelpPage,
        }
    },
})
